<template>
    <div class="pa-0 ma-0">
        <!--Parte visible del componente-->
        <tr>

            <td style=""><div>{{item.nombre_completo}}</div></td>             
            <td style=""><div>{{item.empresa}}</div></td>
            <td style=""><div>{{item.sucursal}}</div></td>
            <td style=""><div>{{item.departamento}}</div></td>
            <td style=""><div>{{item.tipoNomina}}</div></td>


            <td style="min-width:70px!important; max-width:81px!important; width:80px; padding-left:8px;">
                <div class="tblOpciones" v-if="incapacidades.length > 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon fab dark small @click="isOpen ? isOpen = false:isOpen = true">
                            <v-icon class="tamIconoBoton iconoDelgadoBoton">{{isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'}}</v-icon>
                        </v-btn>
                        </template>
                        <span>Desplegar</span>
                    </v-tooltip>
                </div>
            </td>
        </tr>


        <!--Parte desplegable del componente-->
        <template v-if="isOpen">
            <tr class="td-titles">
                <td>
                    <div>
                        Folio
                    </div>
                </td>
                <td>
                    <div>
                        Ramo del seguro
                    </div>
                </td>
                <td>
                    <div>
                        Control
                    </div>
                </td>
                <td>
                    <div>
                        Fecha inicial
                    </div>
                </td>
                <td>
                    <div>
                        Días
                    </div>
                </td>
                <td>
                    <div>
                        Estatus
                    </div>
                </td>
                <td >
                    <div class="tblOpciones">
                        Opciones
                    </div>
                </td>
            </tr>
            <tr :key="incapacidad.id" v-for="incapacidad in incapacidades"  class="td-short">
                               
                <td>
                    <div>
                        {{incapacidad.folio}}
                    </div>
                </td>
                <td>
                    <div>
                        {{incapacidad.descripcionSAT}}
                    </div>
                </td>
                <td>
                    <div>
                        {{incapacidad.rama}}
                    </div>
                </td>
                <td>
                    <div>
                        {{dateFormat(incapacidad.fecha_inicio)}}
                    </div>
                </td>
                <td>
                    <div>
                        {{calcularDias(incapacidad.fecha_inicio, incapacidad.fecha_fin)}}
                    </div>
                </td>
                <td>
                    <v-chip
                        class="mx-0 px-0"
                        color="transparent"
                        :text-color="colorEstatus(incapacidad.estatus)"
                        outlined
                    >
                        <img
                            v-if="incapacidad.estatus === 'AUTORIZADO'"
                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                            src="/static/icon/aprovado.png"
                            alt="Empleado"
                        />
                        <img
                            v-else-if="incapacidad.estatus === 'RECHAZADO'"
                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                            src="/static/icon/cancelado.png"
                            alt="Empleado"
                        />
                        <img
                            v-else-if="incapacidad.estatus === 'PENDIENTE'"
                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                            src="/static/icon/pendiente.png"
                            alt="Empleado"
                        />
                        <img
                            v-else-if="incapacidad.estatus === 'REVISION'"
                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                            src="/static/icon/revision.png"
                            alt="Empleado"
                        />
                            <!-- {{ incidencia.estatus}} -->
                            {{ formatoEstatus(incapacidad.estatus)}}
                    </v-chip>
                </td>
                <td style="padding-left:8px; padding-right:8px;">
                    <div class="tblOpciones">

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="openModalInfo(incapacidad)"  class="botonHover" icon fab dark small>
                                    <v-icon class="iconoDelgadoBoton" v-text="'$visibility_outline'"></v-icon>
                                </v-btn>
                            </template>
                            <span>Ver</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal(incapacidad)">
                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(incapacidad)">
                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                    </div>
                </td>
            </tr>
        </template>
    </div>
</template>


<script>
import GetUrlImg from "@/plugins/Urlimagen";
import moment from 'moment';
moment.locale('es');

export default {
    name: 'expandable-item',
    props: {
        item: {
            type: Object,
            required: true,
            default: ''
        },
        full: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            isOpen: false,
            urlImg : '',
        }
    },
    computed: {
        incapacidades(){
            /* let incapacidades = JSON.parse(JSON.stringify(this.item.incapacidades));
            incapacidades.shift(); */
            return this.item.incapacidades;
        }
    },
    methods: {
        openModalInfo(data){
            this.$emit("openModalInfo", data);
        },
        abrirModal(data){
            data.empleado_id = this.item.empleado_id
            this.$emit("abrirModalUpdate", data);
        },
        eliminar(data){
            this.$emit("eliminar", data);
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        calcularDias(fechaInicio, fechaFin){
            let difDias = moment(fechaFin).diff(moment(fechaInicio), 'days');

            return difDias+1;
        },
        formatoEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return 'Revisión';
                    break;
                case 'RECHAZADO':
                    return 'Rechazado';
                    break;
                case 'AUTORIZADO':
                    return 'Autorizado';
                    break;
                case 'CAPTURA':
                    return 'Captura';
                    break;
                case 'PENDIENTE':
                    return 'Pendiente';
                    break;
                default:
                    return '';
                    break;
            }
        },
        colorEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return '#FCAA7C';
                    break;
                case 'RECHAZADO':
                    return '#F86868';
                    break;
                case 'AUTORIZADO':
                    return '#86EF83';
                    break;
                case 'CAPTURA':
                    return '#FCAA7C';
                    break;
                case 'PENDIENTE':
                    return '#FED543';
                    break;
                default:
                    return '';
                    break;
            }
        },
    },
    //MOUNTED se ejecutan instrucciones una vez se crea el componente;
    mounted () {
        this.urlImg = GetUrlImg.getURL();
    },
}
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
        overflow-x: scroll;
    }
    th{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #828282;
        background-color: #f6f5f6;
        padding: 13px 45px 13px 45px;
    }
    th > div{
        max-width: 15rem;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }
    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: #fdfcfc;
        padding: 13px 45px 13px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
    }

    td > div{
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
        margin-right: auto;
        margin-left: auto;
    }
    td > .loading{
        max-width: 100% !important;
    }
    
    tbody {
      display:block;
      max-height:60vh;
      min-width: 1000px;
    }
    tr {
      display:table;
      table-layout:fixed;
      width: 100%;
    }
    table.ck-table.modal  tbody {
      display:block;
      max-height:60vh;
      min-width: 700px;
    }

    .td-titles td{
        padding-top:1px !important;
        padding-bottom:1px !important;
        background: #f6f6f6;
    }

    .td-short td{
        padding-top:1px !important;
        padding-bottom:1px !important;
        background: #fdfcfc;
    }

    tbody > div {
        vertical-align: middle !important;
    }
    
</style>
import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("nominas-incapacidades/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("nominas-incapacidades/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.post("nominas-incapacidades/update", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("nominas-incapacidades/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("nominas-incapacidades", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    consultarActualizar(parametros) {
        return Vue.axios.get("nominas-incapacidades/consultar-validar", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateFile(parametros) {
        return Vue.axios.post("nominas-incapacidades/updateFile", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

}
import gql from 'graphql-tag';

const incapacidadesTableQuery = gql`
    query incapacidades($whereConditions:IncapacidadesWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        incapacidades(where:$whereConditions, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empresa_id,
                sucursal_id,
                empleado_id,
                SAT_tipo_incapacidad_id,
                nomina_incapacidad_rama_id,
                fecha_inicio,
                fecha_fin,
                estatus,
                folio,
                url_archivo,
                empleado{
                    nombre_completo
                },
                cliente{
                    nombre
                },
                empresa{
                    nombre
                },
                sucursal{
                    nombre
                }
                tipoIncapacidad{
                    descripcion
                }
                ramaIncapacidad{
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const getIncidenciaQuery = gql `
    query incapacidad ($id: ID!) {
        incapacidad (id: $id) {
            id,
            cliente_id,
            empresa_id,
            sucursal_id,
            empleado_id,
            SAT_tipo_incapacidad_id,
            nomina_incapacidad_rama_id,
            fecha_inicio,
            fecha_fin,
            estatus,
            folio,
            url_archivo,
            empleado{
                nombre_completo
            },
            cliente{
                nombre
            },
            empresa{
                nombre
            },
            sucursal{
                nombre
            }
            tipoIncapacidad{
                descripcion
            }
            ramaIncapacidad{
                nombre
            }
        }
    }
`
const getEmpleadosQuery = gql`
    query empleados($whereConditions:EmpleadosWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        empleados(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre_completo,
                foto,
                nombre,
                apellido_paterno,
                apellido_materno
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const queries = {incapacidadesTableQuery, getIncidenciaQuery, getEmpleadosQuery};

export default queries;